import Swiper from 'swiper/bundle';
//トップページ　キービジュアルスライダー
export function idxFunc() {
  console.log("test");
  if ($('#top').length) {
    var heroSwiper, heroOptions;
    heroSwiper = undefined;
    heroOptions = {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
    };
    //スワイプ実行処理
    function initHeroSwiper() {
      heroSwiper = new Swiper('#hero', heroOptions);
    }
    initHeroSwiper();
  }
}