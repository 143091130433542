export function commonFunc() {
  let resizecheck = $('#responsibleCheck').width();
  if (resizecheck == 1) {
    $('.navJsist').hover(
      function () {
        $(this).find('a:first-child').addClass('open');
        $(this).find('ul').stop().slideToggle();
      },
      function () {
        $(this).find('ul').stop().slideToggle();
        $(this).find('a:first-child').removeClass('open');
      }
    );
  } else {
    $('.navJsist a').on('click', function () {
      if($(this).hasClass('child')){
        $(this).next().stop().slideToggle();
        $(this).toggleClass('open');
        console.log("test");
        return false;
      }

    });
  }
  if ($('#jsZoom').length) {
    let src = $('#jsZoom').find('img').attr('src');
    src = '<img src="' + src + '" alt="">';
    $('#overlay').append(src);
    $('#jsZoom').on('click', function () {
      $('#posZoom').fadeIn();
    });
    $('#overlay').on('click', function () {
      $('#posZoom').fadeOut();
    });
  }

  let chosenJsImg, chosenJsSrc;
  if ($('#chosenReason').length) {
    $('.chosenJs').on('click', function () {
      chosenJsImg = $(this).find('img').attr('src');
      chosenJsSrc = '<img src="' + chosenJsImg + '" alt="">';
      $('#overlay').append(chosenJsSrc);
      $('#posZoom').fadeIn();
    });
    $('#overlay').on('click', function () {
      $('#posZoom').fadeOut();
      $("#overlay").empty();
    });
  }

}