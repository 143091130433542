// jQueryプラグイン
import 'jquery.easing';
/* -----------------------------------------------------
ページ内リンクのアニメーション
------------------------------------------------------- */
export function pageLinkAnimationFunc() {
  var scrTop, jsAnkerLogin, moveLogin, position, target, getHeadH;
  scrTop = $('#scrPageTop');
  scrTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1500, 'easeInOutQuint');
    return false;
  });
}

export function pageMoveAnkerFunc() {
  var headerHeight, position;
  $('a[href^="#"]:not(.notScroll)').each(function () {
    var pageUrl = location.href.split('#')[0];
    if (this.href.indexOf(pageUrl + '#') === 0) {
      var id = this.href.split('#')[1];
      $(this).on('click', function () {
        headerHeight = $('#header').outerHeight(true);
        position = $('#' + id).offset().top;
        $('body,html').animate({
          scrollTop: position
        }, 800, 'easeInOutQuart');
        return false;
      });
    }
  });

  let targetScr, positionScr,hash,resizecheck,posH;
  hash = $(location).attr('hash');
  if (hash) {
    setTimeout(function () {
      resizecheck = $('#responsibleCheck').width();
      targetScr = $(hash);
      posH = 0;
      if(resizecheck != '1'){
        $('body,html').stop().scrollTop(0);
        posH = $('#header').outerHeight(true);
      }
      positionScr = targetScr.offset().top - posH;
      $('body,html').animate({
        scrollTop: positionScr
      }, 800, 'easeInOutQuint');
    }, 100);
  }
}